/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import Performance from './performance'

const User = types
  .model({
    id: types.optional(types.union(types.string, types.number), ''),
    email: types.optional(types.string, ''),
    fist_name: types.optional(types.string, ''),
    social_profile_link: types.maybeNull(types.string),
    name_preposition: types.optional(types.string, '', [null, undefined]),
    name: types.optional(types.string, ''),
    description: types.maybeNull(types.string),
    token: types.maybeNull(types.string),
    favorited: types.optional(types.boolean, false),
    favorited_count: types.optional(types.integer, 0),
    performances: types.optional(types.array(Performance), []),
    links: types.optional(types.array(types.string), []),
    artist_count: types.optional(types.integer, 0),
    place_count: types.optional(types.integer, 0),
    duration: types.maybeNull(types.number),
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    avatar: types.maybeNull(types.string),
    provider: types.maybeNull(types.string)
  })

export default User
