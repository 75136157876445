import { Facebook } from '../public/static/svgs/Facebook'
import { Vkontakte } from '../public/static/svgs/Vkontakte'
import { Instagram } from '../public/static/svgs/Instagram'
import { Telegram } from '../public/static/svgs/Telegram'
import { Twitter } from '../public/static/svgs/Twitter'
import { YaMusic } from '../public/static/svgs/YaMusic'
import { Youtube } from '../public/static/svgs/Youtube'
import { Spotify } from '../public/static/svgs/Spotify'
import { SoundCloud } from '../public/static/svgs/SoundCloud'

export default {
  // facebook: Facebook,
  vkontakte: Vkontakte,
  // instagram: Instagram,
  telegram: Telegram,
  twitter: Twitter,
  yandex_music: YaMusic,
  spotify: Spotify,
  youtube: Youtube,
  vk_music: SoundCloud
}

export const socialsDomains = {
  // facebook: 'facebook.',
  twitter: 'twitter.',
  telegram: 't.me',
  // instagram: 'instagram.',
  vkontakte: 'vk.'
}
