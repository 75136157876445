/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const YaMusic = ({ style, className }) => (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} className={className}>
  <path fillRule="evenodd" clipRule="evenodd" d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="black"/>
  <path d="M14.8232 23.1405C18.6467 23.1405 21.7465 20.0448 21.7465 16.2257C21.7465 12.4066 18.6467 9.31077 14.8232 9.31077C10.9998 9.31077 7.9 12.4066 7.9 16.2257C7.9 20.0448 10.9998 23.1405 14.8232 23.1405Z" fill="white" stroke="black" strokeWidth="0.2"/>
  <path d="M17.0594 8.72396V13.0363C16.4238 12.5903 15.6488 12.3282 14.8132 12.3282C12.6506 12.3282 10.9216 14.0749 10.9216 16.2307C10.9216 18.3864 12.6506 20.1331 14.8132 20.1331C16.5997 20.1331 18.109 18.9462 18.5759 17.3068C18.6615 17.0065 18.7063 16.5262 18.7201 16.0213L18.7399 16.0147L18.7399 15.6546L18.7399 9.95624L21.8529 10.9145L22.5 11.1137V10.4367V7.35899V6.98911L22.1463 6.88088L17.7058 5.5219L17.0594 5.3241V6.00001V8.72396Z" fill="white" stroke="black"/>
</svg>
)

YaMusic.propTypes = {
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string
}

YaMusic.defaultProps = {
  style: {},
  className: undefined
}
