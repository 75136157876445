/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const Spotify = ({ style, className }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} className={className}>
    <path d="M15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30ZM21.09 22.07V22.0712C20.0812 22.0712 16.895 18.5363 7.94 20.3713C7.70375 20.4325 7.395 20.5288 7.22 20.5288C6.07625 20.5288 5.8575 18.8175 7.0875 18.5562C12.0413 17.4625 17.1037 17.5588 21.4212 20.1413C22.4512 20.7988 22.0138 22.07 21.09 22.07ZM22.7188 18.1038C22.5775 18.0662 22.6187 18.19 21.9725 17.85C18.1912 15.6125 12.5562 14.71 7.54125 16.0713C7.25125 16.15 7.09375 16.2288 6.82125 16.2288C5.4825 16.2288 5.1275 14.215 6.58625 13.8038C12.4812 12.1475 18.805 13.1138 23.2075 15.7325C23.6975 16.0225 23.8913 16.3988 23.8913 16.9237C23.885 17.5763 23.3775 18.1038 22.7188 18.1038ZM5.685 8.7475C11.3387 7.0925 19.895 7.615 25.215 10.72C26.5788 11.5063 26.0425 13.495 24.5925 13.495L24.5913 13.4937C24.2763 13.4937 24.0825 13.415 23.81 13.2575C19.5063 10.6875 11.805 10.0712 6.8225 11.4625C6.60375 11.5225 6.33125 11.6187 6.04125 11.6187C5.2425 11.6187 4.6325 10.995 4.6325 10.1912C4.6325 9.37 5.14125 8.905 5.685 8.7475Z" fill="black"/>
  </svg>
)

Spotify.propTypes = {
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string
}

Spotify.defaultProps = {
  style: {},
  className: undefined
}
