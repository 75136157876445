import React from 'react'
import App from 'next/app'
import Head from 'next/head'
import * as Sentry from '@sentry/browser'
import { Provider } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import 'setimmediate'
import cookie from 'cookie'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlay,
  faPause,
  faArrowRight,
  faSearch,
  faFilter,
  faMusic,
  faUser,
  faUserCircle,
  faEnvelope,
  faEye,
  faEyeSlash,
  faTimesCircle,
  faFileAlt
} from '@fortawesome/free-solid-svg-icons'
import getConfig from 'next/config'

import { initStore } from '../store'
import { appWithTranslation } from '../i18n'

import '../styles/global.css'

const { publicRuntimeConfig } = getConfig()

Sentry.init({ dsn: 'https://9708b46d4bf3431790d5088b80a5916a@sentry.io/1483733', environment: publicRuntimeConfig.env })

library.add([
  faPlay,
  faPause,
  faArrowRight,
  faSearch,
  faFilter,
  faMusic,
  faUser,
  faUserCircle,
  faEnvelope,
  faEye,
  faEyeSlash,
  faTimesCircle,
  faFileAlt
])

class MyApp extends App {
  static async getInitialProps(props) {
    const { Component, ctx } = props
    const isServer = typeof window === 'undefined'

    let userToken
    if (isServer && ctx.req.headers.cookie) {
      userToken = cookie.parse(ctx.req.headers.cookie).userToken
    }

    const store = initStore(isServer, { token: userToken })
    ctx.mobxStore = store

    let pageProps = {}
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    return {
      initialState: getSnapshot(store),
      isServer,
      pageProps,
      userToken
    }
  }

  componentDidMount() {
    this.store.updateName()
  }

  constructor(props) {
    super(props)
    this.store = initStore(props.isServer, { token: props.userToken }, props.initialState)
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no user-scalable=0"
          />
        </Head>
        <Provider store={this.store}>
          <Component {...pageProps} />
        </Provider>
      </>
    )
  }
}

export default appWithTranslation(MyApp)
