/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'

const Route = types
  .model({
    id: types.integer,
    name: types.maybeNull(types.string),
    name_preposition: types.optional(types.string, '', [null, undefined]),
    logo: types.maybeNull(types.string),
    artist_count: types.integer,
    place_count: types.integer,
    duration: types.maybeNull(types.number),
    favorited: types.boolean
  })

export default Route
