/* eslint-disable no-param-reassign */
import {
  applySnapshot, flow, getSnapshot, types
} from 'mobx-state-tree'
import Artist from './models/artist'
import User from './models/user'
import Place from './models/place'
import Route from './models/route'
import Modal from './models/modal'
import Post from './models/post'
import PostSearch from './models/postSearch'
import { getProfile, getName } from './api/authorization'
import { getEntityByID } from './api/routes'
import { socialsDomains } from './constants/socialIcons'
import { differSocialsLinks, fetchList } from './api/utils'
import programTypeMap from './constants/programTypes'
import { fetchPosts } from './api/posts'

let store

const RootStore = types
  .model({
    user: User,
    artists: types.array(Artist),
    places: types.array(types.string),
    partnerRoutes: types.optional(types.array(Route), []),
    modal: types.optional(Modal, { isOpen: false, message: '' }),
    logoutModal: types.optional(Modal, { isOpen: false, message: '' }),
    successModal: types.optional(Modal, { isOpen: false, message: '' }),
    profileWindow: types.optional(Modal, { isOpen: false, message: '' }),
    searchModal: types.optional(Modal, { isOpen: false, message: '' }),
    searchText: types.optional(types.string, ''),
    artist: types.optional(Artist, {}),
    place: types.optional(Place, {}),
    program: types.optional(User, {}),
    programType: types.maybeNull(types.string),
    post: types.optional(Post, {}),
    promoPost: types.maybeNull(Post),
    posts: types.array(Post),
    postSearch: types.optional(PostSearch, {}),
    headliners: types.map(types.array(Artist))
  })
  .actions(self => ({
    setUser(user) {
      self.user = user
    },
    setArtists(artists) {
      self.artists = artists.map(artist => ({
        ...artist,
        id: artist.id.toString()
      }))
    },
    setProgram(program, programType) {
      self.program = program
      self.programType = programType
    },
    setProgramType(programType) {
      self.programType = programType
    },
    setToken(token) {
      self.user.token = token
    },
    setArtist(artist) {
      self.artist = artist
    },
    setSelectedPerformance(id) {
      const { performances } = self.program
      performances.forEach((performance) => {
        performance.selected = false
      })

      if (id) {
        const toUpdate = performances.find(performance => performance.id === id)
        if (toUpdate) toUpdate.selected = true
      }
    },
    setPartnerRoutes(partnerRoutes) {
      self.partnerRoutes = partnerRoutes
    },
    setPlace(place) {
      self.place = place
      self.place.performances.forEach((performance, i) => {
        self.place.performances[i].name = performance.artist.name
        self.place.performances[i].time = performance.start_datetime.substr(11, 5)
      })
    },
    setLoginEmail(email) {
      if (email) self.modal.setEmail(email)
    },
    updateUser: flow(function* updateUser() {
      const res = yield getProfile(self.user.token)
      if (res) {
        const { token, id } = self.user
        self.user = User.create({ ...res })
        self.user.token = token
        self.user.id = id || self.user.id
        self.program = User.create({ ...res })
      }
    }),
    updateName: flow(function* updateName() {
      if (self.user.id) return
      const res = yield getName(self.user.token)
      if (res) {
        self.user = { ...self.user, ...res }
      }
    }),
    updateRoute: flow(function* updateRoute(routeID) {
      const newRoute = yield getEntityByID(routeID, 'partner_routes', undefined, self.user.token)
      const index = self.partnerRoutes.findIndex(route => route.id === routeID)
      self.partnerRoutes[index] = newRoute || self.partnerRoutes[index]
    }),
    updateRoutes() {
      self.partnerRoutes.forEach(route => self.updateRoute(route.id))
    },
    updateArtist: flow(function* updateArtist(artistID) {
      const newArtist = yield getEntityByID(artistID, 'artists', undefined, self.user.token)
      self.artist = newArtist || self.artist
    }),
    updateProgram: flow(function* updateProgram(entity) {
      if (!(self.program.id || self.user.id)) return
      const program = yield getEntityByID(
        self.program.id || self.user.id,
        entity || programTypeMap[self.programType || 'profile'],
        undefined,
        self.user.token
      )
      self.program = program || self.program
      if (self.program.id === self.user.id) {
        self.user.performances = getSnapshot(self.program.performances)
      }
    }),
    updatePerformance: flow(function* updatePerformance(performanceID) {
      const newPerformance = yield getEntityByID(performanceID, 'performances', undefined, self.user.token)
      newPerformance.name = newPerformance.artist.name
      newPerformance.time = newPerformance.start_datetime.substr(11, 5)
      const index = self.place.performances.findIndex(performance => performance.id === performanceID)
      self.place.performances[index] = newPerformance || self.place.performances[index]
    }),
    loadPost: flow(function* loadPost(postID, language, preview = null) {
      const newPost = yield getEntityByID(postID, 'news', language, self.user.token, { preview })
      self.post = newPost || self.post
    }),
    loadPosts: flow(function* loadPosts({ language, query, more = false }) {
      if (self.postSearch.loading) return
      self.postSearch.loading = true

      Object.assign(self.postSearch.query, query)

      self.postSearch.page = more ? ++self.postSearch.page : 0 // eslint-disable-line
      if (!more) self.posts.length = 0

      const response = yield fetchPosts(self.postSearch.page, self.postSearch.query,
        self.postSearch.limit, language, self.user.token)

      self.posts.push(...response.results)
      self.postSearch.hasMore = response.next !== null
      self.postSearch.loading = false
    }),
    loadPromoPost: flow(function* loadPromoPost({ language, query }) {
      const response = yield fetchPosts(0, query, 1, language)
      self.promoPost = response.results.pop()
    }),
    loadHeadliners: flow(function* loadHeadliners(language) {
      // TODO Нет апи, отдающего хедлайнеров по годам.
      // Отображение headliners надо инициализировать по ключу года и списка хедлайнеров за год
      // Сейчас есть только 2020 год
      if (self.headliners.size <= 0) {
        const headliners = yield fetchList(0,
          { headliner: true, year: 2020 }, 'artists', 12, language, self.user.token)
        self.headliners.set(2020, headliners.results)
      }
    }),
    toggleModal(isOpen, message = '') {
      if (isOpen) self.modal.open(message)
      else self.modal.close()
    },
    toggleLoginModal(isOpen, message = '') {
      if (self.user.token) {
        if (isOpen) self.profileWindow.open(message)
        else self.profileWindow.close()
      } else if (isOpen) self.modal.open(message)
      else self.modal.close()
    },
    toggleLogoutModal(isOpen, message = '') {
      if (isOpen) self.logoutModal.open(message)
      else self.logoutModal.close()
    },
    toggleSuccessModal(isOpen, message = '') {
      self.modal.close()
      if (isOpen) self.successModal.open(message)
      else self.successModal.close()
    },
    toggleSearchModal(isOpen) {
      if (isOpen) self.searchModal.open()
      else self.searchModal.close()
    },
    logout() {
      self.user = User.create({})
      self.profileWindow.close()
      self.logoutModal.close()
    },
    changeSearchText(text) {
      self.searchText = text
    }
  }))
  .views(self => ({
    get getArtists() {
      return self.artists
    },
    get otherLinks() {
      const { others } = differSocialsLinks(self.program.links, socialsDomains)
      return others || []
    },
    get socials() {
      const { socials } = differSocialsLinks(self.program.links, socialsDomains)
      return socials || {}
    },
    get isMyProgram() {
      return self.user.id === self.program.id
    }
  }))

export function initStore(isServer, rawUser, snapshot = null) {
  const user = User.create(rawUser)
  if (isServer) {
    store = RootStore.create({
      artists: [],
      places: [],
      user
    })
  }
  if (store === null) {
    store = RootStore.create({
      artists: [],
      places: [],
      user
    })
  }
  if (snapshot) {
    applySnapshot(store, snapshot)
  }
  return store
}
